import React from "react";
import { faArrowDown, faArrowRight } from "@fortawesome/pro-light-svg-icons";
import FontAwesomeIconWrapper from "/react4xp/shared/FontAwesomeIconWrapper/FontAwesomeIconWrapper";

import type { RelatedLinksProps } from "./types.d";
import "./relatedLinks.scss";

function RelatedLinks(props: RelatedLinksProps) {
  const {
    tittel,
    columns,
    relatedLinks,
    showMargin,
    ariaLabel,
    iconPath
  } = props;

  if (!relatedLinks || relatedLinks.length === 0) {
    return null;
  }

  const getIcon = (typeFile: string) => (typeFile === "PDF" ? faArrowRight : faArrowDown);

  return (
    <div className={`row py-xl ${showMargin ? "l-mb-4" : ""}`}>

      {tittel && (
        <div className="col-xs-12 ">
          <h2 aria-label={ariaLabel} className="l-mb-1 text-neutral">
            {iconPath && (
              <img
                className="float-left w-auto mr-sm h-[24px]"
                src={iconPath}
                alt="ikon"
                role="presentation"
              />
            )}
            {tittel}
          </h2>
          <div className="l-mt-1 l-mb-1">
            <hr className="border-0 h-[var(--design-border-sm)] bg-[var(--design-color-divider-neutral-default)]" />
          </div>
        </div>
      )}

      <div className="col-xs-12">
        <nav>
          <ul className={`list-none m-0 p-0 ${columns === "two" ? "desktop:flex desktop:flex-wrap desktop:justify-between" : ""}`}>

            {relatedLinks.map((relatedLink) => (
              <li key={relatedLink.url} className={`linkWithBorder relative hover:bg-surface-action-menu-hover ${columns === "two" ? "desktop:flex-[0_0_47.5%] desktop:box-border" : ""}`}>
                <a
                  href={relatedLink.url}
                  target={relatedLink.target}
                  className="block text-left relative cursor-pointer py-md px-sm h-full tablet:p-sm shadow-none"
                >

                  {!relatedLink.type ? (
                    // Content type type
                    <p className="w-full flex flex-row justify-between items-center text-neutral">
                      {relatedLink.tittel}
                      <FontAwesomeIconWrapper
                        icon={faArrowRight}
                        size="lg"
                        className="arrowAnimation mr-md ml-sm text-icon-action"
                      />
                    </p>
                  ) : (

                  // Media type
                    <div className="flex justify-between items-start tablet:items-center">
                      <p className="flex-3 pr-xs text-neutral">{relatedLink.tittel}</p>
                      <p className="text-md underline text-action ml-md pr-md shrink-0 relative">
                        {relatedLink.typeFile}
                        <FontAwesomeIconWrapper
                          icon={getIcon(relatedLink.typeFile)}
                          size="lg"
                          className="ml-sm text-icon-action"
                        />
                      </p>
                    </div>
                  )}

                </a>
              </li>
            ))}

          </ul>
        </nav>
      </div>

    </div>
  );
}

export default function(props: RelatedLinksProps) {
  return <RelatedLinks {...props} />;
}
